<template>
    <div class="searchbar">
        <svg width="24" height="24" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 38C30.3888 38 38 30.3888 38 21C38 11.6112 30.3888 4 21 4C11.6112 4 4 11.6112 4 21C4 30.3888 11.6112 38 21 38Z" fill="none" stroke="#3D9FA0" stroke-width="4" stroke-linejoin="round"/><path d="M26.657 14.3431C25.2093 12.8954 23.2093 12 21.0001 12C18.791 12 16.791 12.8954 15.3433 14.3431" stroke="#3D9FA0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M33.2216 33.2217L41.7069 41.707" stroke="#3D9FA0" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <input type="text" placeholder="搜索" />
    </div>
</template>

<script>
    export default {
    name: 'searchbar',
    props: {

    },
    methods: {

    }
}
</script>

<style scoped>
.searchbar {
    border: 2px solid #3D9FA0;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    margin: 100px;
    width: 50vw;
}
input {
    border: none;
    outline: none;
    background-color: transparent;
    color: #3D9FA0;
    font-size: 20px;
    padding: 10px;
}
</style>
  