<template>
  <div class="home">
    <img class="logoimg" alt="logo" src="../assets/logo.png">
    <h1>像素名片 支持</h1>
    <h3 style="color: #3D9FA0; padding-bottom: 100px;" >Pixel Card Support</h3>
    <div class="card-container">
      <Card icon="usinghelp.png" title="使用帮助" description="你也许在使用过程中出现了问题，又或者是...你根本不知道如何使用" link="https://pixelcard.dmxx.ltd/" linkText="我需要开发者的帮助"/>
      <Card icon="comment.png" title="意见" description="如果你觉得这个小小的名片软件需要改进...当然可以，开发者很愿意为使用者们修改增添功能" link="https://pixelcard.dmxx.ltd/" linkText="我想说"/>
      <Card icon="contact.png" title="联系App开发者" description="如果针对于App你有一些废话想对开发者说的话" link="https://pixelcard.dmxx.ltd/" linkText="hello开发者"/>
    </div>
    <SearchBar/>
    <div class="footer">
      <a class="footerText">Copyright @ 2023 Damien</a>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue'
import SearchBar from '@/components/SearchBar.vue'

export default {
  name: 'HomeView',
  components: {
    Card,
    SearchBar
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
}
.logoimg {
  width: 200px;
  height: 200px;
  border-radius: 50px;
}
.card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.footerText{
  text-decoration: none;
  color: #3D9FA0;
  padding-top: 100px;
}
</style>